/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useEffect } from "react";
import { Container, Box, Flex, NavLink } from "@theme-ui/components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text } from "@theme-ui/components";
import NavBar from "../components/NavBar";
import { navigate } from "gatsby-link";
import Seo from "../components/seo";
import {
	Label,
	Input,
	Select,
	Textarea,
	Radio,
	Checkbox,
	Slider,
} from "theme-ui";
import Footer from "../components/footer";

const Registration = () => {
	return (
		<div>
			<Seo />
			<NavBar />
			<Container
				sx={{
					minHeight: "85vh",
				}}
			>
				<Grid p={2} columns={[1, 1, 2]}>
					<Box
						py={4}
						as="form"
						name="contact"
						method="post"
						action="/thanks/"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						// onSubmit={handleSubmit}
					>
						<Label htmlFor="username">Full Name</Label>
						<Input
							name="name"
							id="name"
							mb={3}
							// onChange={handleChange}
							required
						/>
						<Label htmlFor="username">eMail</Label>
						<Input
							type="email"
							name="email"
							id="email"
							mb={3}
							// onChange={handleChange}
							required
						/>

						<Label htmlFor="comment">Question/Comment</Label>
						<Textarea
							name="comment"
							id="comment"
							rows={6}
							mb={3}
							// onChange={handleChange}
							required
						/>

						<Button as="button" type="submit">
							Submit
						</Button>
					</Box>
				</Grid>
			</Container>
			<Footer />
		</div>
	);
};

export default Registration;
